import { useEffect } from "react";
import "../css/set-name.css";

const SetName = ({
  isName,
  setIsName,
  name,
  setName,
  setIsPlay,
  setIsStart,
}) => {
  const handleNameChange = (e) => {
    let yourName = e.target.value;
    setName(yourName);
  };

  const handleNameClick = () => {
    const nameArr = ["Cat", "Dog", "Rabbit", "Fish"];
    setIsName(true);
    if (!name) {
      setName(nameArr[Math.floor(4 * Math.random())]);
    }
    setIsPlay(true);
    setIsStart(false);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        handleNameClick();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <>
      <div className="logo"></div>
      <div className="set-name">
        <form>
          <label htmlFor="name-user">Enter your name:</label>
          <div className="input-name">
            <input
              id="name-user"
              className="name-user"
              placeholder="Enter your name"
              value={name}
              onChange={handleNameChange}
            ></input>
            <button type="submit" onClick={handleNameClick}>
              Let's Go
            </button>
          </div>
        </form>
      </div>
      <div className="frame frame-start"></div>
    </>
  );
};

export default SetName;
