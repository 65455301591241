import React from "react";
import "../../css/features/menu.css";

const Menu = ({ setIsTutorial, setIsPlay, setIsInfo, isStart }) => {
  return (
    <div id="menu">
      <nav className="menu">
        <ul className="nav-link">
          <li className="link">
            <a
              href="#what?"
              onClick={(e) => {
                if (isStart) {
                  e.preventDefault();
                } else {
                  setIsTutorial(false);
                  setIsPlay(false);
                  setIsInfo(true);
                }
              }}
            >
              What?
            </a>
          </li>
          <li className="link">
            <a
              href="#tutorial"
              onClick={(e) => {
                if (isStart) {
                  e.preventDefault();
                } else {
                  setIsTutorial(true);
                  setIsPlay(false);
                  setIsInfo(false);
                }
              }}
            >
              Tutorial
            </a>
          </li>
          <li className="link">
            <a
              href="#play"
              onClick={() => {
                setIsTutorial(false);
                setIsPlay(true);
                setIsInfo(false);
              }}
            >
              Play
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
