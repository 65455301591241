import React from "react";
import "../css/tutorial.css";
import Menu from "./features/Menu";

const Introduction = ({ setIsTutorial, setIsPlay, setIsInfo }) => {
  const menuProps = {
    setIsTutorial,
    setIsPlay,
    setIsInfo,
  };
  return (
    <>
      <div className="logo"></div>
      <Menu {...menuProps}></Menu>
      <div className="top-players">
        <div className="example"></div>
      </div>
      <div className="frame frame-start"></div>
    </>
  );
};

export default Introduction;
