import AWS from "aws-sdk";

//Config
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

// Create Obj DynamoDB Document Client
const dynamoDB = new AWS.DynamoDB();

export default dynamoDB;
