/* eslint-disable no-unused-vars */
import { useState } from "react";
import Reward from "./components/Reward";
import GameModeOptions from "./components/GameModeOptions";
import Game from "./components/Game";
// import Overview from "./components/Overview";
import "./css/default.css";
import SetName from "./components/SetName";
import Tutorial from "./components/Tutorial";
import Introduction from "./components/Introduction";

function App() {
  const [isStart, setIsStart] = useState(false);
  const [done, setDone] = useState(false);
  const [isName, setIsName] = useState(false);
  // const [isOverview, setIsOverview] = useState(false);
  const [scores, setScores] = useState(0);
  const [selectedTimer, setSelectedTimer] = useState(180);
  const [totalTime, setTotalTime] = useState(0);
  const [selectedOperator, setSelectedOperator] = useState("+");
  const [selectedNoOfOperator, setSelectedNoOfOperator] = useState("1");
  const [selectedOperator1, setSelectedOperator1] = useState("+");
  const [selectedOperator2, setSelectedOperator2] = useState("+");
  const [selectedDigitNumber, setSelectedDigitNumber] = useState("1");
  const [isTutorial, setIsTutorial] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [isInfo, setIsInfo] = useState(true);
  const [name, setName] = useState("");
  const [question, setQuestion] = useState("");
  const rewardProps = {
    scores,
    isStart,
    setIsStart,
    setDone,
    done,
    name,
    totalTime,
    setTotalTime,
    selectedNoOfOperator,
    selectedDigitNumber,
    setIsTutorial,
    setIsPlay,
    setIsInfo,
  };
  const gameModeOptionProps = {
    selectedTimer,
    selectedOperator,
    selectedNoOfOperator,
    selectedDigitNumber,
    selectedOperator1,
    selectedOperator2,
    setSelectedTimer,
    setSelectedOperator,
    setSelectedNoOfOperator,
    setSelectedDigitNumber,
    setSelectedOperator1,
    setSelectedOperator2,
    isStart,
    setIsStart,
    setScores,
    setQuestion,
    setIsTutorial,
    setIsPlay,
    setIsInfo,
  };
  const gameProps = {
    scores,
    done,
    selectedDigitNumber,
    selectedOperator,
    selectedTimer,
    setDone,
    setScores,
    question,
    setQuestion,
    selectedNoOfOperator,
    selectedOperator1,
    selectedOperator2,
    name,
    totalTime,
    setTotalTime,
    setIsTutorial,
    setIsPlay,
    setIsInfo,
    isStart,
  };

  const setNameProps = {
    isName,
    setIsName,
    name,
    setName,
    setIsPlay,
    setIsStart,
  };

  const tutorialProps = {
    setIsTutorial,
    setIsPlay,
    setIsInfo,
    isStart,
  };

  const introductionProps = {
    setIsTutorial,
    setIsPlay,
    setIsInfo,
  };

  if (isTutorial) {
    return <Tutorial {...tutorialProps}></Tutorial>;
  } else if (isInfo) {
    return <Introduction {...introductionProps}></Introduction>;
  } else {
    if (!isName) {
      return <SetName {...setNameProps}></SetName>;
    } else {
      return (
        <>
          {!isStart ? (
            <GameModeOptions {...gameModeOptionProps}></GameModeOptions>
          ) : done ? (
            <Reward {...rewardProps}></Reward>
          ) : (
            <Game {...gameProps}></Game>
          )}
        </>
      );
    }
  }
}
export default App;
