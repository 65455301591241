import { createQuestion } from "../utils/createQuestion";
import { useEffect } from "react";
import "../css/game-mode-option.css";
import Menu from "./features/Menu";
const GameModeOptions = ({
  selectedTimer,
  selectedOperator,
  selectedNoOfOperator,
  selectedDigitNumber,
  selectedOperator1,
  selectedOperator2,
  setSelectedTimer,
  setSelectedOperator,
  setSelectedNoOfOperator,
  setSelectedDigitNumber,
  setSelectedOperator1,
  setSelectedOperator2,
  isStart,
  setIsStart,
  setScores,
  setQuestion,
  setIsTutorial,
  setIsPlay,
  setIsInfo,
}) => {
  const menuProps = {
    setIsTutorial,
    setIsPlay,
    setIsInfo,
    isStart,
  };
  const handleSelectedTimer = (e) => {
    const value = e.target.value;
    setSelectedTimer(value);
  };

  const handleSelectedOperator = (e) => {
    const value = e.target.value;
    setSelectedOperator(value);
  };

  const handleSelectedDigitNumber = (e) => {
    const value = e.target.value;
    setSelectedDigitNumber(value);
  };

  const handleSelectedNoOfOperator = (e) => {
    const value = e.target.value;
    setSelectedNoOfOperator(value);
  };

  const handleSelectedOperator1 = (e) => {
    const value = e.target.value;
    setSelectedOperator1(value);
  };

  const handleSelectedOperator2 = (e) => {
    const value = e.target.value;
    setSelectedOperator2(value);
  };

  const handleStartClick = () => {
    setIsStart(true);
    setScores(0);
    setQuestion(
      createQuestion(
        selectedOperator,
        selectedOperator1,
        selectedOperator2,
        selectedDigitNumber,
        selectedNoOfOperator
      )
    );
  };

  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.key === "Enter" && !isStart) {
        handleStartClick();
      }
    };
    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedOperator,
    selectedOperator1,
    selectedOperator2,
    selectedDigitNumber,
    selectedNoOfOperator,
  ]);
  return (
    <>
      <div className="logo"></div>
      <Menu {...menuProps}></Menu>
      <div id="game-mode">
        <div className="box-mode">
          <h2>Please choose a game mode</h2>
          <div className="mode">
            <div className="mode-time">
              <label htmlFor="level-time">Time</label>
              <select
                id="level-time"
                value={selectedTimer}
                onChange={handleSelectedTimer}
              >
                <option value="10">10s/question</option>
                <option value="20">20s/question</option>
                <option value="60">60s/question</option>
                <option value="180">180s/question</option>
              </select>
            </div>

            <div className="mode-no-of-operator">
              <label htmlFor="operator">No of Operator</label>
              <select
                id="no-of-operator"
                value={selectedNoOfOperator}
                onChange={handleSelectedNoOfOperator}
              >
                <option value="1">One (1)</option>
                <option
                  value="2"
                  style={{
                    display:
                      selectedOperator === "*" ||
                      selectedOperator === "/" ||
                      selectedOperator === "random"
                        ? "none"
                        : "block",
                  }}
                >
                  Two (2)
                </option>
              </select>
            </div>
            {selectedNoOfOperator === "1" ? (
              <div className="mode-operator">
                <label htmlFor="operator">Operator</label>
                <select
                  id="operator"
                  value={selectedOperator}
                  onChange={handleSelectedOperator}
                >
                  <option
                    value="random"
                    style={{
                      display: selectedNoOfOperator === "2" ? "none" : "block",
                    }}
                  >
                    Random
                  </option>
                  <option value="+">Addition (+)</option>
                  <option value="-">Subtraction (-)</option>
                  <option
                    value="*"
                    style={{
                      display: selectedNoOfOperator === "2" ? "none" : "block",
                    }}
                  >
                    Multiplication (*)
                  </option>
                  <option
                    value="/"
                    style={{
                      display: selectedNoOfOperator === "2" ? "none" : "block",
                    }}
                  >
                    Division (/)
                  </option>
                </select>
              </div>
            ) : (
              <>
                <div className="mode-operator">
                  <label htmlFor="operator1">Operator 1</label>
                  <select
                    id="operator1"
                    value={selectedOperator1}
                    onChange={handleSelectedOperator1}
                  >
                    <option value="+">Addition (+)</option>
                    <option value="-">Subtraction (-)</option>
                  </select>
                </div>
                <div className="mode-operator">
                  <label htmlFor="operator2">Operator 2</label>
                  <select
                    id="operator2"
                    value={selectedOperator2}
                    onChange={handleSelectedOperator2}
                  >
                    <option value="+">Addition (+)</option>
                    <option value="-">Subtraction (-)</option>
                  </select>
                </div>
              </>
            )}

            <div className="mode-digit">
              <label htmlFor="digit-number">Digit number</label>
              <select
                id="digit-number"
                value={selectedDigitNumber}
                onChange={handleSelectedDigitNumber}
              >
                <option value="1">One (1)</option>
                <option value="2">Two (2)</option>
              </select>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="button-main"
          onClick={handleStartClick}
        >
          Start
        </button>
      </div>
      <div className="frame frame-start"></div>
    </>
  );
};

export default GameModeOptions;
