import React from "react";
import "../css/introduction.css";
import Menu from "./features/Menu";
const Tutorial = ({ setIsTutorial, setIsPlay, setIsInfo, isStart }) => {
  const menuProps = {
    setIsTutorial,
    setIsPlay,
    setIsInfo,
    isStart,
  };
  return (
    <>
      <div className="logo"></div>
      <Menu {...menuProps}></Menu>
      <div className="tutorial">
        <div className="tutorial-content">
          <p>
            Finger Math is a mathematical technique that uses fingers as a
            visual aid for performing calculations. It is commonly used as a
            tool to teach basic arithmetic concepts, particularly addition and
            subtraction, to young children.
          </p>
          <p>
            Finger Math helps children develop number sense, improve their
            counting skills, and gain a conceptual understanding of basic
            arithmetic operations. It engages their tactile and visual senses,
            making math more tangible and accessible. It is often used as a
            foundational learning tool before children progress to mental math
            strategies.
          </p>
        </div>
        <br />
        <div className="tutorial-image"></div>
      </div>
      <div className="frame"></div>
    </>
  );
};

export default Tutorial;
