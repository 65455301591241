export function handleImages(
  n,
  setFingerLeft,
  setFingerRight,
  setFingerFrame,
  key
) {
  let unit = n % 10;
  let dezons = n - unit;
  setFingerLeft((prev) => ({
    ...prev,
    [key]: `fingerLeft_${dezons} fingerBase`,
  }));
  setFingerRight((prev) => ({
    ...prev,
    [key]: `fingerRight_${unit} fingerBase`,
  }));
  setFingerFrame("frameHide");
}
