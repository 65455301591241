// import * as math from "mathjs";

export function createQuestion(
  selectedOperator,
  selectedOperator1,
  selectedOperator2,
  selectedDigitNumber,
  selectedNoOfOperator
) {
  let operand1 = Math.ceil(9 * Math.random());
  let operand2 = Math.ceil(9 * Math.random());
  let operator, question;

  // Generate a 2-digit operand
  if (selectedDigitNumber === "2") {
    operand1 = Math.ceil(90 * Math.random()) + 9;
    operand2 = Math.ceil(90 * Math.random()) + 9;
  }

  // Generate a random operator
  const operatorArr = ["+", "-", "*", "/"];
  if (selectedOperator === "random") {
    operator = operatorArr[Math.floor(4 * Math.random())];
  } else {
    operator = selectedOperator;
  }

  // Process to ensure divisibility
  if (operator === "/") {
    if (selectedDigitNumber === "1") {
      operand2 = Math.ceil(4 * Math.random());
      operand1 = operand2 + Math.floor(6 * Math.random());
    } else {
      operand2 = Math.ceil(16 * Math.random()) + 9;
      operand1 = operand2 + Math.floor(75 * Math.random());
    }
    if (operand1 % operand2 !== 0) {
      operand1 = operand2 * Math.floor(operand1 / operand2);
    }
  }

  // Process to ensure the operation is not negative
  if (operator === "-") {
    if (operand1 < operand2 && selectedDigitNumber === "1") {
      operand1 = operand2 + Math.floor((9 - operand2) * Math.random());
    }

    if (operand1 < operand2 && selectedDigitNumber === "2") {
      operand1 = operand2 + Math.floor((99 - operand2) * Math.random());
    }
  }
  question = `${operand1} ${operator} ${operand2} =`;

  // Handling aggregation operations (2 operators)
  if (selectedNoOfOperator === "2") {
    let operand3;
    if (selectedDigitNumber === "1") {
      if (selectedOperator1 === "-" && selectedOperator2 === "-") {
        operand3 = Math.floor(9 * Math.random());
        operand2 = Math.floor((9 - operand3) * Math.random());
        operand1 =
          operand2 +
          operand3 +
          Math.ceil((9 - operand2 - operand3) * Math.random());
      } else if (selectedOperator1 === "+" && selectedOperator2 === "+") {
        operand3 = Math.floor(9 * Math.random());
        operand2 = Math.floor(9 * Math.random());
        operand1 = Math.floor(9 * Math.random());
      } else {
        operand3 = Math.floor(9 * Math.random());
        operand2 = Math.floor(9 * Math.random());
        operand1 =
          operand2 +
          operand3 +
          Math.floor((9 - operand2 - operand3) * Math.random());
      }
    } else {
      if (selectedOperator1 === "-" && selectedOperator2 === "-") {
        operand3 = Math.floor(90 * Math.random()) + 10;
        operand2 = Math.floor((90 - operand3) * Math.random()) + 10;
        operand1 =
          operand2 +
          operand3 +
          Math.ceil((90 - operand2 - operand3) * Math.random()) +
          9;
      } else if (selectedOperator1 === "+" && selectedOperator2 === "+") {
        operand3 = Math.floor(90 * Math.random()) + 10;
        operand2 = Math.floor(90 * Math.random()) + 10;
        operand1 = Math.floor(90 * Math.random()) + 10;
      } else {
        operand3 = Math.floor(90 * Math.random()) + 10;
        operand2 = Math.floor(90 * Math.random()) + 10;
        operand1 =
          operand2 +
          operand3 +
          Math.floor((90 - operand2 - operand3) * Math.random()) +
          10;
      }
    }
    question = `${operand1} ${selectedOperator1} ${operand2} ${selectedOperator2} ${operand3} =`;
  }
  return question;
}
