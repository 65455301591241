/* eslint-disable react-hooks/exhaustive-deps */
// Component Game
import "../css/game.css";
import React, { useState, useEffect } from "react";
import * as math from "mathjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createQuestion } from "../utils/createQuestion";
import { handleImages } from "../utils/handleImages";
import { useKeyPress } from "../utils/useKeyPress";
import Menu from "./features/Menu";

const Game = ({
  scores,
  done,
  selectedDigitNumber,
  selectedOperator,
  selectedTimer,
  setDone,
  setScores,
  question,
  setQuestion,
  selectedNoOfOperator,
  selectedOperator1,
  selectedOperator2,
  name,
  totalTime,
  setTotalTime,
  setIsTutorial,
  setIsPlay,
  setIsInfo,
  isStart,
}) => {
  const next = "Next";
  const [operatorArrs, setOperatorArrs] = useState([]);
  const [result, setResult] = useState(`Can you answer this?`);
  const [answer, setAnswer] = useState("");
  const [count, setCount] = useState(1);
  const [input, setInput] = useState("0");
  const [timer, setTimer] = useState(selectedTimer);
  const [disabled, setDisabled] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [fingerLeft, setFingerLeft] = useState({});
  const [fingerRight, setFingerRight] = useState({});
  const [fingerFrame, setFingerFrame] = useState("frame");
  const menuProps = {
    setIsTutorial,
    setIsPlay,
    setIsInfo,
    isStart,
  };
  const handleConfirmation = (prompt, action) => {
    if (isConfirm === false) {
      const confirmToast = toast.info(
        <div className="custom-toast">
          <span className="button-close" onClick={() => handleNo(confirmToast)}>
            x
          </span>
          <h6>Submit Record</h6>
          <p>{prompt}</p>
          <span>This action cannot be Undone</span>
          <div className="button-confirm">
            <button
              className="button-no"
              onClick={() => handleNo(confirmToast)}
            >
              Cancel
            </button>
            <button className="button-yes" onClick={() => action()}>
              Yes
            </button>
          </div>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          draggable: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: false,
        }
      );
      setDisabled(true);
      setIsConfirm(true);
    }
  };

  const handleYesNoInput = (confirmToast) => {
    toast.dismiss(confirmToast);
    handleExpression();
    setIsConfirm(false);
  };

  const handleYesNoSubmit = (confirmToast) => {
    toast.dismiss(confirmToast);
    if (count > 9) {
      setDone(!done);
    } else {
      handleNextClick();
    }
    setIsConfirm(false);
  };

  const handleNo = (confirmToast) => {
    toast.dismiss(confirmToast);
    setDisabled(false);
    setIsConfirm(false);
    return;
  };

  const handleNextClick = () => {
    // removeToastifyDiv()
    setQuestion(
      createQuestion(
        selectedOperator,
        selectedOperator1,
        selectedOperator2,
        selectedDigitNumber,
        selectedNoOfOperator
      )
    );
    setCount((prev) => prev + 1);
    setResult(`Question No ${count + 1}`);
    setInput("0");
    setAnswer("");
    setTimer(selectedTimer);
    setDisabled(false);
    setIsConfirm(false);
    setFingerLeft("");
    setFingerRight("");
    setFingerFrame("frame");
    setOperatorArrs([]);
  };
  const handleClick = (value) => {
    switch (value) {
      case "Del":
        if (input.length === 1) {
          setInput("0");
          return;
        }
        setInput(input.slice(0, -1));
        break;
      case "C":
        setInput("0");
        break;
      case "NEXT":
        if (disabled === false && isConfirm === false) {
          handleConfirmation(
            "Are you sure to next without submit?",
            handleYesNoSubmit
          );
          return;
        } else {
          if (count > 9) {
            setDone(!done);
          } else {
            handleNextClick();
          }
          break;
        }
      default:
        if (input.length > 5) {
          alert("Results are less than 5 characters");
        } else {
          setInput(input === "0" ? value : input + value);
        }
        break;
    }
  };

  const handleExpression = () => {
    const arrMath = question.split("=");
    const evaluatedExpression = math.evaluate(arrMath[0]);
    setAnswer(evaluatedExpression);
    if (evaluatedExpression < 100) {
      const regexNumber = /[0-9]/;
      const operatorArr = /[+\-*\\/]/;
      const operandArr = arrMath[0].split(operatorArr);
      let operator = arrMath[0].split(regexNumber);
      operator = operator.filter((item) => item !== "" && item !== " ");
      operator[operator.length] = "=";
      setOperatorArrs(operator);
      handleImages(
        operandArr[0],
        setFingerLeft,
        setFingerRight,
        setFingerFrame,
        "operand1"
      );
      handleImages(
        operandArr[1],
        setFingerLeft,
        setFingerRight,
        setFingerFrame,
        "operand2"
      );
      if (operandArr.length === 3) {
        handleImages(
          operandArr[2],
          setFingerLeft,
          setFingerRight,
          setFingerFrame,
          "operand3"
        );
      } else {
        setFingerLeft((prev) => ({
          ...prev,
          operand3: "frameHide",
        }));
        setFingerRight((prev) => ({
          ...prev,
          operand3: "frameHide",
        }));
      }
      handleImages(
        evaluatedExpression,
        setFingerLeft,
        setFingerRight,
        setFingerFrame,
        "result"
      );
    }
    if (evaluatedExpression !== Number(input)) {
      setResult("Incorrect !!");
    } else {
      setResult("Correct !!");
      setScores(
        (prev) => prev + Math.round((10 + (10 * timer) / selectedTimer) / 2)
      );
    }
    setDisabled(true);
    setTotalTime((prev) => prev + selectedTimer - timer);
  };

  const handleSubmitClick = () => {
    const result = math.evaluate(question.split("=")[0]);
    if (result === 0) {
      handleExpression();
    } else {
      if (input === "0") {
        handleConfirmation(
          "Are you sure to submit without result?",
          handleYesNoInput
        );
        return;
      }
      handleExpression();
    }
  };

  const handleDelete = () => {
    setInput("0");
  };

  // Timer.
  useEffect(() => {
    const countTimer = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    const outTimer = setTimeout(() => {
      if (count > 9) {
        setDone(!done);
      } else {
        handleNextClick();
      }
      if (document.querySelector(".Toastify__toast")) {
        document.querySelector(".Toastify__toast").remove();
      }
    }, selectedTimer * 1000);

    return () => {
      clearInterval(countTimer);
      clearTimeout(outTimer);
    };
  }, [count]);

  // Handle keydown.
  const onKeyPress = (e) => {
    handleClick(e.key);
  };
  useKeyPress(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"], onKeyPress);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        if (disabled) {
          return;
        } else {
          handleSubmitClick();
        }
      }
      if (e.key === "Backspace" || e.key === "Delete") {
        handleClick("C");
      }
      if (e.key === "Space" || e.key === " ") {
        if (document.querySelector(".Toastify__toast")) {
          return;
        } else {
          handleClick("NEXT");
        }
      }
      if (e.key === "y" || e.key === "Y") {
        const yesButton = document.getElementsByClassName("button-yes")[0];
        if (yesButton && isConfirm) {
          yesButton.click();
        }
      }
      if (e.key === "n" || e.key === "N") {
        const noButton = document.getElementsByClassName("button-no")[0];
        if (noButton && isConfirm) {
          noButton.click();
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    // cleanup this component
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [input, disabled, count]);
  const conclude = `${question} ${answer} `;
  return (
    <>
      <ToastContainer />
      <div className="logo"></div>
      <Menu {...menuProps}></Menu>
      <div className="box-game" style={{ opacity: isConfirm ? 0.3 : 1 }}>
        <div className="box-calculator">
          <h6
            className="result"
            style={{
              color:
                result === "Incorrect !!"
                  ? "red"
                  : result === "Correct !!"
                  ? "green"
                  : "black",
            }}
          >
            {count === 1 ? (
              <>
                Hey <span style={{ color: "red" }}>{name}</span>, {result}
              </>
            ) : (
              result
            )}
          </h6>
          <div className="calculator-main">
            <div className="calculator" id="calculator">
              <div
                className="input"
                style={{
                  color:
                    result === "Incorrect !!"
                      ? "red"
                      : result === "Correct !!"
                      ? "green"
                      : "black",
                }}
              >
                {conclude}
              </div>

              <div className="box-handle">
                <div className="answer">
                  {input} <span onClick={handleDelete}>&times;</span>
                </div>
                <div className="row">
                  <p className="score">{`Score: ${scores} /100`}</p>
                  <span className="time">{`Time: ${timer} s`}</span>
                </div>
                <div className="button-panel">
                  <button
                    className="item1"
                    disabled={disabled}
                    onClick={() => handleClick("1")}
                  >
                    1
                  </button>
                  <button
                    className="item2"
                    disabled={disabled}
                    onClick={() => handleClick("2")}
                  >
                    2
                  </button>
                  <button
                    className="item3"
                    disabled={disabled}
                    onClick={() => handleClick("3")}
                  >
                    3
                  </button>
                  <button
                    className="item4"
                    disabled={disabled}
                    onClick={() => handleClick("4")}
                  >
                    4
                  </button>
                  <button
                    className="item5"
                    disabled={disabled}
                    onClick={() => handleClick("5")}
                  >
                    5
                  </button>
                  <button
                    className="item6"
                    disabled={disabled}
                    onClick={() => handleClick("6")}
                  >
                    6
                  </button>
                  <button
                    className="item7"
                    disabled={disabled}
                    onClick={() => handleClick("7")}
                  >
                    7
                  </button>
                  <button
                    className="item8"
                    disabled={disabled}
                    onClick={() => handleClick("8")}
                  >
                    8
                  </button>
                  <button
                    className="item9"
                    disabled={disabled}
                    onClick={() => handleClick("9")}
                  >
                    9
                  </button>
                  <button
                    className="itemDel"
                    disabled={disabled}
                    onClick={() => handleClick("Del")}
                  >
                    D
                  </button>
                  <button
                    className="item0"
                    disabled={disabled}
                    onClick={() => handleClick("0")}
                  >
                    0
                  </button>
                  <button
                    className="itemC"
                    disabled={disabled}
                    onClick={() => handleClick("C")}
                  >
                    C
                  </button>
                </div>
                <div className="row row-button">
                  <button
                    className="button-submit"
                    type="submit"
                    onClick={handleSubmitClick}
                    disabled={disabled}
                    style={{ opacity: disabled ? 0 : 1 }}
                  >
                    Submit
                  </button>
                  <button
                    className="itemNext"
                    disabled={isConfirm}
                    onClick={() => handleClick("NEXT")}
                  >
                    {next}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={fingerFrame}></div>
      <div className="finger">
        <div className="finger-operand-1">
          <div className={fingerLeft["operand1"]}></div>
          <div className={fingerRight["operand1"]}></div>
        </div>
        <span>{operatorArrs[0]}</span>
        <div className="finger-operand-2">
          <div className={fingerLeft["operand2"]}></div>
          <div className={fingerRight["operand2"]}></div>
        </div>
        {fingerLeft["operand3"] === "frameHide" ? (
          ""
        ) : (
          <>
            <span>{operatorArrs[1]}</span>
            <div className="finger-operand-3">
              <div className={fingerLeft["operand3"]}></div>
              <div className={fingerRight["operand3"]}></div>
            </div>
          </>
        )}
        <span>{operatorArrs[operatorArrs.length - 1]}</span>
        <div className="finger-result">
          <div className={fingerLeft["result"]}></div>
          <div className={fingerRight["result"]}></div>
        </div>
      </div>
    </>
  );
};

export default Game;
