/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import dynamoDB from "../services/dynamodb";
import "../css/reward.css";
import Menu from "./features/Menu";
const Reward = ({
  scores,
  isStart,
  setIsStart,
  setDone,
  done,
  name,
  totalTime,
  setTotalTime,
  selectedNoOfOperator,
  selectedDigitNumber,
  setIsTutorial,
  setIsPlay,
  setIsInfo,
}) => {
  const menuProps = {
    setIsTutorial,
    setIsPlay,
    setIsInfo,
    isStart,
  };
  let medal = "medal";
  let note = "note";
  let notes = "Not enough to receive a reward";
  if (scores < 60) {
    medal = "medal";
    note = "note medal4";
  }
  if (scores >= 60) {
    medal = "medal medal3";
  }
  if (scores >= 70) {
    medal = "medal medal2";
  }
  if (scores >= 80) {
    medal = "medal medal1";
  }
  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.key === "Enter") {
        setIsStart(!isStart);
        setDone(!done);
        handleAddData();
      }
    };
    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  const handleAddData = () => {
    // Config table
    const params = {
      TableName: "finger-math-db",
      Item: {
        id: {
          S: uuidv4(),
        },
        userName: {
          S: name,
        },
        score: {
          S: scores.toString(),
        },
        totalTime: {
          S: totalTime.toString(),
        },
        date: {
          S: new Date().toISOString(),
        },
      },
    };
    // Put item
    if ((selectedDigitNumber === "1") & (selectedNoOfOperator === "1")) {
      console.log(123);
      dynamoDB.putItem(params, (err) => {
        if (err) {
          console.error(err);
        } else {
          console.log("Record added successfully:");
        }
      });
    }
    setTotalTime(0);
    // setIsOverview(true);
  };

  return (
    <>
      <div className="logo"></div>
      <Menu {...menuProps}></Menu>
      <div className="award">
        <h6 className="score">
          Congrats <span style={{ color: "red" }}>{name}</span> <br></br>You got{" "}
          {scores}
          /100
        </h6>
        <span className={note}>{notes}</span>
        <div className={medal}></div>
        <button
          type="button"
          className="finish button-main"
          onClick={() => {
            setIsStart(!isStart);
            setDone(!done);
            // handleAddData();
          }}
        >
          Finish
        </button>
      </div>

      <div className="frame"></div>
    </>
  );
};
export default Reward;
